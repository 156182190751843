import React, { useEffect, useState} from 'react';
import {useAppSelector} from '../../app/hooks';
import AppContainer from '../../components/common/layout/AppContainer';
import AppCard from '../../components/common/layout/AppCard';
import {
    ScoreBarChartData,
    ScoreBarData,
} from '../../models/stats/ScoreCharts';
import { StreakItem } from '../../models/stats/StreakItems';
import { generateStreakItems } from '../../utils/statsUtils';
import {Helmet} from "react-helmet";

const StatsPage: React.FC = () => {
    const [scoreBarChartData, setScoreBarChartData] =
        useState<ScoreBarChartData>();
    const [streakItems, setStreakItems] = useState<StreakItem[]>();

    const stats = useAppSelector((state) => state.stats);

    const generateBarChartData = () => {
        const total = stats.previousRounds.length;
        const bars: ScoreBarData[] = [];

        for (let i = 1; i <= 6; i++) {
            bars.push({
                label: i.toString(),
                count: 0,
            });
        }

        bars.push({
            label: 'X',
            count: 0,
        });

        stats.previousRounds.forEach((item, index) => {
            if (!item.win) {
                bars[6].count += 1;
            } else {
                bars[item.guessCount - 1].count += 1;
            }
        });

        setScoreBarChartData({
            total,
            bars,
        });
    };

    useEffect(() => {
        generateBarChartData();
        setStreakItems(generateStreakItems(stats, 'all', false, false));
    }, [stats]);

    return (
        <AppContainer
            outerClassName="max-h-full"
            innerClassName="w-full h-full items-center py-4 text-accent-1"
        >
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Statistics
            </h2>

            <AppCard>
                {scoreBarChartData ? (
                    <div className="flex flex-col">
                        {scoreBarChartData.bars.map((item, index) => {
                            const scorePercent =
                                (item.count / scoreBarChartData.total) * 100;

                            return (
                                <div
                                    key={index}
                                    className="flex flex-row h-6 mb-1 last:mb-0"
                                >
                                    <div
                                        className={`w-5 text-center ${
                                            item.count > 0
                                                ? 'text-accent-1'
                                                : 'text-accent-2'
                                        }`}
                                    >
                                        {item.label}
                                    </div>
                                    <div className="flex-grow mx-2 my-1">
                                        <div
                                            className="h-full bg-accent-1"
                                            style={{
                                                width: `${scorePercent}%`,
                                            }}
                                        />
                                    </div>
                                    <div className="w-5 text-right">
                                        {item.count > 0 ? item.count : ''}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </AppCard>
            {streakItems && (
                <AppCard className="mt-4">
                    <table className="table-fixed text-center w-full">
                        <thead>
                            <tr className="text-sm">
                                <th className="w-8"></th>
                                <th className="text-left">Streak</th>
                                <th className="w-14">Current</th>
                                <th className="w-14">Best</th>
                            </tr>
                        </thead>
                        <tbody>
                            {streakItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-left pr-1">
                                            {item.label.slice(0, 2)}
                                        </td>
                                        <td className="text-left">
                                            {item.label.slice(3)}
                                        </td>
                                        <td className="pr-1">{item.current}</td>
                                        <td>{item.best}</td>
                                    </tr>
                                );
                                /*return (
                    <div
                        key={index}
                        className="flex flex-row mb-1 last:mb-0"
                    >
                        <div className="flex-grow">{item.label}</div>
                        <div className="text-right">{item.value}</div>
                    </div>
                );*/
                            })}
                        </tbody>
                    </table>
                </AppCard>
            )}
        </AppContainer>
    );
};
export default StatsPage;
