import { Entity } from '../common';
import { AdminClip, Clip } from './clip';
import { FlickRecord } from './flick';

type RoundDataBase = {
    number: number;
    date: string;
    ready: boolean;
    flickId: string;
    flickTitle: string;
    isReplay: boolean;
    replayRoundId?: string;
    replayRoundNumber?: number;
    replayRoundDate?: string;
};

type ArchiveDataBase = {
    number: number;
    date: string;
    flickId: string;
    flickTitle: string;
    pastAt: string;
    record?: {
        guessHistory: number[];
        isWin: boolean;
    };
    previouslyCompleted: boolean;
};

type AdminRoundDataBase = RoundDataBase & {
    ready: boolean;
    preparedById?: string;
    preparedByNickname?: string;
    liveAt: string;
    pastAt: string;
};

export type RoundDataShort = RoundDataBase;
export type RoundDataLong = RoundDataBase & {
    clips: Clip[];
};

export type AdminRoundDataShort = AdminRoundDataBase;
export type AdminRoundDataLong = AdminRoundDataBase & {
    clips: AdminClip[];
};

export type ArchiveDataShort = ArchiveDataBase;
export type ArchiveDataLong = ArchiveDataBase & {
    clips: Clip[];
};

export type RoundShort = Entity & RoundDataShort;
export type RoundLong = Entity & RoundDataLong;
export type AdminRoundShort = Entity & AdminRoundDataShort;
export type AdminRoundLong = Entity & AdminRoundDataLong;
export type ArchiveRoundShort = Entity & ArchiveDataShort;
export type ArchiveRoundLong = Entity & ArchiveDataLong;

export type RoundRecord = Omit<
    Entity &
        RoundDataLong & {
            flick: FlickRecord;
        },
    | 'clips'
    | 'flickId'
    | 'flickTitle'
    | 'ready'
    | 'isReplay'
    | 'replayRoundNumber'
>;

export const roundRecordFromRound = (round: RoundLong): RoundRecord => {
    return {
        id: round.id,
        date: round.date,
        number: round.number,
        flick: {
            id: round.flickId,
            title: round.flickTitle,
        },
    };
};

export const archiveRecordFromRound = (round: RoundLong): RoundRecord => {
    return {
        id: round.replayRoundId ?? round.id,
        date: round.replayRoundDate ?? round.date,
        number: round.replayRoundNumber ?? round.number,
        flick: {
            id: round.flickId,
            title: round.flickTitle,
        },
    };
};

export const archiveRecordFromArchiveRound = (
    round: ArchiveRoundLong,
): RoundRecord => {
    return {
        id: round.id,
        date: round.date,
        number: round.number,
        flick: {
            id: round.flickId,
            title: round.flickTitle,
        },
    };
};
