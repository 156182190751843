import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player/file';
import { Clip } from '../../models/game/clip';
import { LogDebug } from '../../utils/loggingUtils';
import AppContainer from '../common/layout/AppContainer';
import LoadingSpinner from '../common/misc/LoadingSpinner';

type PlayerProps = {
    isPlaying: boolean;
    setIsPlaying: (isPlaying: boolean) => void;
    clips: Clip[];
    guessIndex: number;
    clipIndex: number;
    setSelectedClipIndex: (index: number) => void;
    showPlayer: boolean;
    isLoading: boolean;
    isReady: boolean;
    gameOver: boolean;
    mode?: 'primary' | 'secondary';
    showReplayFlag?: boolean;
};

const Player: React.FC<PlayerProps> = ({
    isPlaying,
    setIsPlaying,
    clips,
    guessIndex,
    clipIndex,
    setSelectedClipIndex,
    showPlayer,
    isLoading,
    isReady,
    gameOver,
    mode = 'primary',
    showReplayFlag = false,
}) => {
    const selectedClip = clips
        ? clipIndex === 7
            ? clips.find((clip) => clip.position === 7)
            : clips[clipIndex]
        : undefined;

    return (
        <AppContainer
            padding={false}
            maxWidth="1024px"
            innerClassName="flex flex-col w-full items-center"
            outerClassName="bg-black border-b border-accent-1"
        >
            <div
                className="relative h-full w-full"
                style={{
                    maxHeight: 'calc(40vh - 1rem)',
                    height: '56.25vw',
                }}
            >
                {showReplayFlag && (
                    <div className="absolute flex top-0 w-full z-10 justify-center">
                        <span className="leading-none text-center text-secondary-1 font-bold bg-canvas-6 p-1 pb-1.5 my-1 rounded animate-flash">
                            Replay
                        </span>
                    </div>
                )}
                {showPlayer && selectedClip && (
                    <ReactPlayer
                        className="absolute top-0 left-0 bg-transparent"
                        controls={true}
                        width="100%"
                        height="100%"
                        muted={false}
                        playing={isPlaying}
                        onPlay={() => {
                            setIsPlaying(true);
                            LogDebug('Playing Clip', selectedClip.sourceUrl);
                        }}
                        onPause={() => {
                            setIsPlaying(false);
                            LogDebug('Pausing Clip', selectedClip.sourceUrl);
                        }}
                        loop={true}
                        url={selectedClip.sourceUrl}
                    />
                )}
                {isLoading && (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        {!isReady ? (
                            <LoadingSpinner className="w-24 h-24" />
                        ) : (
                            <span className="block text-accent-1 uppercase text-2xl mx-4">
                                Coming Soon
                            </span>
                        )}
                    </div>
                )}
            </div>
            <div
                className={clsx(
                    'w-full flex',
                    showPlayer || isLoading ? 'visible' : 'invisible',
                )}
            >
                {Array(6)
                    .fill(null)
                    .map((item, index) => {
                        const bgColor =
                            clipIndex === index
                                ? mode === 'primary'
                                    ? 'bg-primary-1'
                                    : 'bg-secondary-1'
                                : gameOver || index <= guessIndex
                                ? 'bg-canvas-5'
                                : 'bg-canvas-6';
                        const textColor =
                            clipIndex === index
                                ? 'text-accent-1'
                                : gameOver || index <= guessIndex
                                ? mode === 'primary'
                                    ? 'text-primary-1'
                                    : 'text-secondary-1'
                                : 'text-canvas-4';
                        return (
                            <div
                                key={index}
                                onClick={() => setSelectedClipIndex(index)}
                                className={`${bgColor} ${textColor} ${
                                    clipIndex === index || index > guessIndex
                                        ? 'cursor-default'
                                        : 'cursor-pointer'
                                } flex-1 flex items-center justify-center h-5 text-center text-xs font-bold mr-1 last:mr-0`}
                            >
                                <span>{index + 1}</span>
                            </div>
                        );
                    })}
            </div>
        </AppContainer>
    );
};

export default Player;
