import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArchiveRoundShort } from '../../models/game/round';
import {
    getDateTimeUtcNow,
    getDiffNow,
    getDurationFormat,
} from '../../utils/dateTimeUtils';
import {
    generateEmojiGuessesFromArchive,
    generateStreakMedalsFromArchive,
} from '../../utils/statsUtils';

export type ArchiveProps = {
    className?: string;
    index: number;
    round: ArchiveRoundShort;
};
const ArchiveItem: React.FC<ArchiveProps> = ({ className, index, round }) => {
    const roundDate = DateTime.fromISO(round.date, {
        zone: 'utc',
    });
    const pastAt = DateTime.fromISO(round.pastAt, {
        zone: 'utc',
    });
    const roundInPast = getDateTimeUtcNow().diff(pastAt).as('milliseconds') > 0;
    //const archiveRoundRecords =  useAppSelector((state) => state.stats.archiveRounds);
    //const existingRoundRecord = archiveRoundRecords.find((r) => r.round.id === round.id || r.round.number === round.number);
    const durationUntilPast = getDiffNow(pastAt);
    const untilPastFormat = getDurationFormat(durationUntilPast);

    const getContents = () => {
        return (
            <>
                <div
                    className={clsx(
                        'relative flex w-full border-b leading-none py-1',
                        round.flickId === undefined && 'border-canvas-5',
                        roundInPast ? 'text-accent-1' : 'text-accent-2',
                    )}
                >
                    <span className="flex-1 block py-1 pl-2">
                        {`#${round.number}`}
                    </span>
                    <div>
                        {round.previouslyCompleted && !round.record && (
                            <span className="block text-center py-1 text-secondary-1">
                                Replay
                            </span>
                        )}
                    </div>
                    <span className="flex-1 block text-right py-1 pr-2">{`${roundDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )}`}</span>
                    <span className="absolute w-full block flex-grow text-center py-1">
                        {round.record
                            ? generateStreakMedalsFromArchive(
                                  round.record.guessHistory,
                                  round.record.isWin,
                              )
                            : ''}
                    </span>
                </div>
                <div
                    className={clsx(
                        'w-full flex-grow leading-none flex items-center',
                        roundInPast ? 'text-accent-1' : 'text-accent-2',
                    )}
                >
                    <span className={`block w-full text-center py-4 px-1`}>
                        {round.record
                            ? round.flickTitle
                            : roundInPast
                            ? '???'
                            : `Unlocks in ${durationUntilPast.toFormat(
                                  untilPastFormat,
                              )}`}
                    </span>
                </div>
                {round.record && (
                    <div
                        className={clsx(
                            'w-full text-center text-sm pb-1 px-1 border-t',
                        )}
                    >
                        <span className="block my-1">
                            {generateEmojiGuessesFromArchive(
                                round.record.guessHistory,
                                round.record.isWin,
                            )}
                        </span>
                    </div>
                )}
            </>
        );
    };

    const containerClasses = () =>
        clsx(
            className,
            round.record ? 'text-accent-1' : 'bg-canvas-6',
            round.record
                ? 'border border-accent-1 text-accent-1'
                : 'border border-canvas-5',
            roundInPast ? 'cursor-pointer' : 'cursor-default',
            'w-full flex flex-col items-center min-h-[5rem]',
        );

    const containerRouterLink = (
        children: React.ReactElement,
    ): React.ReactElement => {
        return (
            <Link to={`/archive/${round.id}`} className={containerClasses()}>
                {children}
            </Link>
        );
    };

    const containerDiv = (children: React.ReactElement): React.ReactElement => {
        return <div className={containerClasses()}>{children}</div>;
    };

    return roundInPast
        ? containerRouterLink(getContents())
        : containerDiv(getContents());
};

export default ArchiveItem;
