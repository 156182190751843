import React, {useCallback, useState} from 'react';
import {
    setCookiePolicyStatus,
    setShareLink,
    setShareOverrideCopyToClipboard,
    setShareStats,
    setShareStatsBest,
    setShareStatsNegative,
    setShareStatsShort,
} from '../../store/settingsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AppCheckbox from '../../components/common/inputs/AppCheckbox';
import AppContainer from '../../components/common/layout/AppContainer';
import AppCard from '../../components/common/layout/AppCard';
import AppButton from "../../components/common/inputs/AppButton";
import Scrollbars from "react-custom-scrollbars-2";
import {importStats, StatsState} from "../../store/statsSlice";
import {useClearArchiveRecordsMutation} from "../../app/services/api";
import AppIconInput from "../../components/common/forms/AppIconInput";
import {setSessionId} from "../../store/accountSlice";
import {Helmet} from "react-helmet";

type ExportData = {
    stats: StatsState,
    sessionId: string

}
const SettingsPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const [confirmResetArchive, setConfirmResetArchive] = useState(false);
    const [archiveReset, setArchiveReset] = useState(false);

    const stats = useAppSelector((state) => state.stats);
    const account = useAppSelector((state) => state.account);
    const settings = useAppSelector((state) => state.settings);

    const [clearArchiveRecords, {isLoading: isClearingArchive} ] = useClearArchiveRecordsMutation();

    const submitArchiveReset = () => {
        if (!account.sessionId) return

        clearArchiveRecords({sessionId: account.sessionId})
            .unwrap()
            .then(() => {
                setConfirmResetArchive(false);
                setArchiveReset(true);
            },
            (error) => {
                console.error(error);
            })
    }

    const exportStats = () => {
        if (!account.sessionId) return

        const exportData: ExportData = {
            stats: stats,
            sessionId: account.sessionId
        }

        const data = JSON.stringify(exportData);
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'flickle_stats.json';
        a.click();
        URL.revokeObjectURL(url);
    }

    const importStatsFile = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file: File | undefined =
                event.target.files !== null && event.target.files.length > 0
                    ? event.target.files[0]
                    : undefined;

            if (file === undefined) return;

            // extract json from file
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target?.result;
                if (typeof contents === 'string') {
                    const data = JSON.parse(contents) as ExportData;

                    dispatch(setSessionId(data.sessionId));
                    dispatch(importStats(data.stats));
                }
            };
            reader.readAsText(file);
        },
        [dispatch],
    );

    return (
        <AppContainer
            outerClassName="max-h-full"
            innerClassName="w-full h-full items-center py-4 text-accent-1 flex flex-col"
            fillScreen={true}
        >
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Settings
            </h2>

            <div className="w-full h-full max-h-full overflow-hidden">
                <Scrollbars>
                    <AppCard className="mb-4">
                        <h3 className="text-xl text-center font-semibold mb-4">
                            Share Options
                        </h3>
                        <div className="flex flex-row mb-2">
                            <p className="flex-grow">Include Link</p>
                            <AppCheckbox
                                value={settings.shareLink}
                                setValue={() => {
                                    dispatch(setShareLink(!settings.shareLink));
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-2">
                            <p className="flex-grow">Include Streaks</p>
                            <AppCheckbox
                                value={settings.shareStats}
                                setValue={() => {
                                    dispatch(setShareStats(!settings.shareStats));
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-2 ml-4">
                            <p className="flex-grow text-sm">Best Streaks</p>
                            <AppCheckbox
                                value={settings.shareStatsBest}
                                enabled={settings.shareStats}
                                setValue={() => {
                                    dispatch(
                                        setShareStatsBest(!settings.shareStatsBest),
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-2 ml-4">
                            <p className="flex-grow text-sm">Short Streaks</p>
                            <AppCheckbox
                                value={settings.shareStatsShort}
                                enabled={settings.shareStats}
                                setValue={() => {
                                    dispatch(
                                        setShareStatsShort(!settings.shareStatsShort),
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-2 ml-4">
                            <p className="flex-grow text-sm">Anti-Streaks</p>
                            <AppCheckbox
                                value={settings.shareStatsNegative}
                                enabled={settings.shareStats}
                                setValue={() => {
                                    dispatch(
                                        setShareStatsNegative(
                                            !settings.shareStatsNegative,
                                        ),
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-row">
                            <p className="flex-grow">Always Copy to Clipboard</p>
                            <AppCheckbox
                                value={settings.shareOverrideCopyToClipboard}
                                setValue={() => {
                                    dispatch(
                                        setShareOverrideCopyToClipboard(
                                            !settings.shareOverrideCopyToClipboard,
                                        ),
                                    );
                                }}
                            />
                        </div>
                    </AppCard>

                    <AppCard className="flex items-center mb-4">
                        <h3 className="text-xl text-center font-semibold">
                            Stats
                        </h3>
                        <div className="flex flex-row justify-center mt-8 mb-2">
                            <AppButton value="Export" sizeClassName="h-8 w-32"
                                       className="uppercase mr-2 hover:bg-transparent" mode="primary"
                                       onClick={exportStats}/>
                            <AppIconInput
                                type="file"
                                layoutClassName="h-8 w-32"
                                animationsEnabled={false}
                                onChange={(e) => {
                                    importStatsFile(
                                        e,
                                    );
                                }}
                            >
                                <AppButton value="Import" sizeClassName="h-8 w-32" className="uppercase ml-2"
                                           mode="secondary"/>
                            </AppIconInput>
                        </div>
                    </AppCard>

                    <AppCard className="flex items-center mb-4">
                        <h3 className="text-xl text-center font-semibold">
                        Archive
                        </h3>

                        {confirmResetArchive && <span className="block mt-4 mb-2">Are you sure? This cannot be undone</span>}
                        {!confirmResetArchive ?
                            <AppButton value="Reset Archive Progress" sizeClassName="h-8 w-64" className="uppercase mt-12 mb-2" mode="errorPrimary" onClick={() => setConfirmResetArchive(true)}/>
                            : <div className="mb-2">
                                <AppButton value="Continue" sizeClassName="h-8 w-32" className="uppercase mr-2" mode="errorPrimary" onClick={submitArchiveReset} isLoading={isClearingArchive}/>
                                <AppButton value="Cancel" sizeClassName="h-8 w-32" className="uppercase ml-2" mode="errorSecondary" onClick={() => setConfirmResetArchive(false)} isEnabled={!isClearingArchive}/>
                            </div>
                        }
                        {archiveReset && <span className="block mt-2 mb-2 text-center">Archive reset</span>}
                    </AppCard>

                    <AppCard className="flex items-center mb-4">
                        <h3 className="text-xl text-center font-semibold">
                            Privacy
                        </h3>
                        <AppButton value="Review Policy Consent" sizeClassName="h-8 w-64 mt-8 mb-2" className="uppercase" onClick={() => dispatch(setCookiePolicyStatus("pending"))}/>
                    </AppCard>
                </Scrollbars>
            </div>

        </AppContainer>
    );
};
export default SettingsPage;
