import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Controller, {ControllerCallables} from "../../../components/game/Controller";
import {
    useCreateArchiveCompletionRecordMutation,
    useGetArchiveQuery,
    useLazyGetNextArchiveQuery, useLazyGetRandomArchiveQuery
} from "../../../app/services/api";
import {useNavigate, useParams} from "react-router-dom";
import Player from "../../../components/game/Player";
import Scrollbars from "react-custom-scrollbars-2";
import GuessHistory from "../../../components/game/GuessHistory";
import Adsense from "../../../components/common/misc/Adsense";
import clsx from "clsx";
import AppContainer from "../../../components/common/layout/AppContainer";
import {Guess} from "../../../models/game/guess";
import {FlickSearchResult} from "../../../models/game/flick";
import ArchiveEndScreen from "../../../components/archive/ArchiveEndScreen";
import {useAppSelector} from "../../../app/hooks";
import {generateArchiveGuesses, generateGuessesFromArchive} from "../../../utils/statsUtils";
import {ApiRequestArchiveCompletionRecord} from "../../../models/api/archive";
import {LogDebug} from "../../../utils/loggingUtils";
import {ResponseModel} from "../../../models/api/common/response";
import {Entity} from "../../../models/common";
import {ArchiveRoundLong} from "../../../models/game/round";
import LoadingSpinner from "../../../components/common/misc/LoadingSpinner";
import {Helmet} from "react-helmet";

const ArchivePage: React.FC = () => {
    let {roundId} = useParams();
    const navigate = useNavigate();

    const scrollbars = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [isWin, setIsWin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [controllerCallables, setControllerCallables] = useState<ControllerCallables>();
    const [selectedClipIndex, setSelectedClipIndex] = useState(0);
    const [guessIndex, setGuessIndex] = useState(0);
    const [guesses, setGuesses] = useState<Guess[]>([]);
    const [roundsAvailable, setRoundsAvailable] = useState<boolean>(true);
    const [currentRound, setCurrentRound] = useState<ArchiveRoundLong | undefined>(undefined);

    const account = useAppSelector((state) => state.account);

    const [createArchiveCompletionRecord] = useCreateArchiveCompletionRecordMutation();
    const getArchiveResponse = useGetArchiveQuery({
        sessionId: account.sessionId!,
        roundId: roundId!
    }, {skip: !account.sessionId || !roundId});
    const [getNextArchiveTrigger, getNextArchiveResponse] =
        useLazyGetNextArchiveQuery();
    const [getRandomArchiveTrigger, getRandomArchiveResponse] =
        useLazyGetRandomArchiveQuery();

    useEffect(() => {
        if (currentRound && currentRound.id === getArchiveResponse.data?.content.id) return;
        setIsLoading(true);
        setCurrentRound(getArchiveResponse.data?.content);
    }, [currentRound, getArchiveResponse.data?.content]);

    useEffect(() => {
        if (!currentRound || getArchiveResponse.data?.content.id !== currentRound.id) return;

        setIsLoading(false);

        if (currentRound.record) {
            setGuesses(generateGuessesFromArchive(currentRound.record.guessHistory));
            setIsWin(currentRound.record.isWin);
            setIsGameOver(true);
            setGuessIndex(currentRound.record.guessHistory.length);
        } else {
            resetState();
        }
    }, [currentRound, getArchiveResponse.data?.content.id]);

    useEffect(() => {
        if (isGameOver && scrollbars !== null && scrollbars.current !== null) {
            // @ts-ignore
            scrollbars.current.scrollTop(0);
        }
    }, [isGameOver]);

    useEffect(() => {
        setSelectedClipIndex(guessIndex);
    }, [guessIndex]);

    useEffect(() => {
        if (isGameOver) {
            setSelectedClipIndex(7);
        }
    }, [isGameOver]);

    const setSelectedClip = useCallback((index: number) => {
        if (index !== selectedClipIndex && (isGameOver || index <= guessIndex)) {
            setSelectedClipIndex(index);
        }
    }, [guessIndex, isGameOver, selectedClipIndex]);

    const addGuess = (option: FlickSearchResult) => {
        if (!currentRound || !option) return;

        const guess: Guess = {
            flick: option,
            isSkip: false,
            isCorrect: option.id === currentRound.flickId,
        };

        const newGuesses = [...guesses, guess];
        const gameOver = newGuesses.length === 6 || guess.isCorrect;
        const win = guess.isCorrect;
        setGuesses(newGuesses);
        setIsWin(win);
        setIsGameOver(gameOver);
        setGuessIndex(newGuesses.length);

        if (!gameOver || !account.sessionId) return;

        const request: ApiRequestArchiveCompletionRecord = {
            sessionId: account.sessionId,
            roundId: currentRound.id,
            guessHistory: generateArchiveGuesses(newGuesses),
            isWin: win,
        };

        createArchiveCompletionRecord(request)
            .unwrap()
            .then(() => {
                LogDebug(
                    `Create archive round completion for session ${request.sessionId} and round ${request.roundId}`,
                );
            }, console.error);
    }

    const skipGuess = () => {
        if (!currentRound) return;

        const guess: Guess = {
            isSkip: true,
            isCorrect: false,
        };

        const newGuesses = [...guesses, guess];
        const gameOver = newGuesses.length === 6;
        setGuesses(newGuesses);
        setIsGameOver(gameOver);
        setGuessIndex(newGuesses.length);

        if (!gameOver || !account.sessionId) return;

        const request: ApiRequestArchiveCompletionRecord = {
            sessionId: account.sessionId,
            roundId: currentRound.id,
            guessHistory: generateArchiveGuesses(newGuesses),
            isWin: false,
        };

        createArchiveCompletionRecord(request)
            .unwrap()
            .then(() => {
                LogDebug(
                    `Create archive round completion for session ${request.sessionId} and round ${request.roundId}`,
                );
            }, console.error);
    }

    const resetState = () => {
        setGuesses([]);
        setIsWin(false);
        setIsGameOver(false);
        setGuessIndex(0);
    }

    const getRandomArchive = () => {
        if (!currentRound || !account.sessionId) return;

        getRandomArchiveTrigger({
            currentRoundId: currentRound.id,
            sessionId: account.sessionId,
        })
            .unwrap()
            .then(
                (response: ResponseModel<Entity>) => {
                    setCurrentRound(undefined);
                    navigate(`/archive/${response.content.id}`, {
                        replace: true,
                    });
                },
                (error) => {
                    console.warn('payload', error.payload);
                    if (error.status === 404) {
                        setRoundsAvailable(false);
                    }
                },
            );
    };

    const getNextArchive = () => {
        if (!currentRound || !account.sessionId) return;

        getNextArchiveTrigger({
            currentRoundId: currentRound.id,
            sessionId: account.sessionId,
        })
            .unwrap()
            .then(
                (response: ResponseModel<Entity>) => {
                    setCurrentRound(undefined);
                    navigate(`/archive/${response.content.id}`, {
                        replace: true,
                    });
                },
                (error) => {
                    console.error(error);
                    if (error.status === 404) {
                        setRoundsAvailable(false);
                    }
                },
            );
    };

    const player = useMemo(() => <Player
            clips={currentRound?.clips || []}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            guessIndex={guessIndex}
            clipIndex={selectedClipIndex}
            setSelectedClipIndex={setSelectedClip}
            showPlayer={currentRound !== undefined}
            isLoading={currentRound === undefined}
            isReady={currentRound !== undefined}
            gameOver={isGameOver}
            mode="secondary"
            showReplayFlag={currentRound?.previouslyCompleted && !currentRound.record}
        />,
        [guessIndex, isGameOver, isPlaying, currentRound, selectedClipIndex, setSelectedClip]);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            {!isGameOver && player}
            <Scrollbars
                ref={scrollbars}
                className="flex-grow overflow-y-auto"
                renderThumbVertical={(props) => (
                    <div
                        {...props}
                        className="thumb-vertical bg-canvas-4 rounded-full"
                    />
                )}
            >
                {currentRound && !isLoading ? isGameOver ? (
                    <>
                        {player}
                        <ArchiveEndScreen
                            win={isWin}
                            round={currentRound!}
                            guesses={guesses}
                            getNextArchive={getNextArchive}
                            getRandomArchive={getRandomArchive}
                            fetchingNextArchive={
                                getNextArchiveResponse.isLoading
                                || getNextArchiveResponse.isFetching
                                || getRandomArchiveResponse.isLoading
                                || getRandomArchiveResponse.isFetching
                            }
                            roundsAvailable={roundsAvailable}
                        />
                    </>
                ) : (
                    <GuessHistory
                        setSearchFocused={controllerCallables?.setFocus}
                        guessIndex={guessIndex}
                        gameOver={isGameOver}
                        guesses={guesses}
                    />
                ) : <LoadingSpinner className="mt-16 w-24 h-24"/>}

                <div id="left-ad" className="hidden lg:block fixed left-0 center-panel-ad-container">
                    <Adsense
                        className={clsx("w-full h-full")}
                        slot="7808203916"
                        style={{
                            display: 'block'
                        }}
                    />
                </div>

                <div id="right-ad" className="hidden lg:block fixed right-0 center-panel-ad-container">
                    <Adsense
                        className={clsx("w-full h-full")}
                        slot="9460196934"
                        style={{
                            display: 'block'
                        }}
                    />
                </div>
            </Scrollbars>
            <div className="z-10">
                {isGameOver ? null : (
                    <Controller
                        setCallables={setControllerCallables}
                        roundReady={true}
                        guesses={guesses}
                        submitGuessFunction={addGuess}
                        skipGuessFunction={skipGuess}
                        mode="secondary"
                    />
                )}
            </div>
            <div className="z-10">
                <AppContainer
                    outerClassName={clsx("h-[4.5rem] pt-4 w-full", isGameOver ? 'bg-canvas-6' : 'bg-canvas-5')}
                    innerClassName="w-full h-full"
                    maxWidth="1024px"
                    padding={false}>
                    <Adsense
                        className={clsx("w-full h-full")}
                        slot="9121285588"
                        responsive={true}
                        style={{
                            display: 'block'
                        }}
                    />
                </AppContainer>
            </div>
        </>
    );
}

export default ArchivePage;
