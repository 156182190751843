import React, {useEffect, useState} from "react";
import ArchiveList from "../../../components/archive/ArchiveList";
import {ApiRequestArchiveList} from "../../../models/api/archive";
import {useGetArchiveListQuery} from "../../../app/services/api";
import {useLocation, useNavigate} from "react-router-dom";
import AppContainer from "../../../components/common/layout/AppContainer";
import clsx from "clsx";
import Adsense from "../../../components/common/misc/Adsense";
import {useAppSelector} from "../../../app/hooks";

const ArchiveListPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageNumber = queryParams.get('page') || '1';

    const account = useAppSelector((state) => state.account);

    const [queryRequest, setQueryRequest] = useState<ApiRequestArchiveList>();

    const queryResponse = useGetArchiveListQuery(queryRequest!, {
        refetchOnMountOrArgChange: true,
        skip: queryRequest === undefined,
    });

    const tryParsePageNumber = (pageNumber: string) => {
        const parsed = parseInt(pageNumber);
        if (isNaN(parsed)) return 1;
        return parsed;
    }

    useEffect(() => {
        if (account.sessionId === undefined) return;
        setQueryRequest({
            sessionId: account.sessionId,
            pageNumber: Math.max(tryParsePageNumber(pageNumber), 1),
            pageSize: 25,
        });
    }, [account.sessionId, pageNumber]);

    const updatePage = (difference: number) => {
        if (queryRequest === undefined) return;
        const currentPageNumber = tryParsePageNumber(pageNumber);

        let newPageNumber =
            difference > 0
                ? Math.max(currentPageNumber, 1) + 1
                : Math.max(Math.min(currentPageNumber - 1, (queryResponse.data?.content.totalPages ?? 999) - 1 ), 1);

        navigate(`/archive?page=${newPageNumber}`);
    };

    return <>
        <title>Flickle - The daily movie guessing game</title>
        <meta
            name="description"
            content="Check out the archive of all previous rounds of Flickle."
        />
        <ArchiveList
            hasPreviousPage={false}
            hasNextPage={false}
            updatePage={updatePage}
            queryResponse={queryResponse}
        />
        <div className="z-10">
            <AppContainer outerClassName={clsx("h-[4.5rem] pt-4 w-full bg-canvas-6",)}
                          innerClassName="w-full h-full"
                          maxWidth="1024px"
                          padding={false}>
                <Adsense
                    className={clsx("w-full h-full")}
                    slot="5180936926"
                    responsive={true}
                    style={{
                        display: 'block'
                    }}
                />
            </AppContainer>
        </div>
    </>
}

export default ArchiveListPage;
