import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { useLoginMutation } from '../../app/services/api';
import AppFormSubmitButton from '../../components/common/forms/AppFormSubmitButton';
import AppFormTextInput from '../../components/common/forms/AppFormTextInput';
import AppCard from '../../components/common/layout/AppCard';
import AppContainer from '../../components/common/layout/AppContainer';
import { ApiRequestLogin, ApiResultLogin } from '../../models/api/account';
import { ResponseModel } from '../../models/api/common/response';
import { setTokens, setUserInfo } from '../../store/accountSlice';

const LoginPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ApiRequestLogin>();

    const [login, { isLoading: isLoggingIn }] = useLoginMutation();

    const onSubmit = (request: ApiRequestLogin) => {
        try {
            login(request)
                .unwrap()
                .then((response: ResponseModel<ApiResultLogin>) => {
                    dispatch(setTokens(response.content));
                    dispatch(setUserInfo(response.content));
                });
            navigate('/');
        } catch (loginError) {
            console.warn(loginError);
        }
    };

    return (
        <AppContainer
            outerClassName="max-h-full"
            innerClassName="w-full h-full items-center py-4 text-accent-1"
        >
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Login
            </h2>

            <AppCard>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col items-center"
                >
                    <AppFormTextInput
                        type="email"
                        placeholder="user@email.com"
                        autocomplete="username"
                        formProperties={register('email')}
                        className="mb-4"
                    />

                    <div className="mb-4 w-full">
                        <AppFormTextInput
                            type="password"
                            placeholder="********"
                            autocomplete="current-password"
                            formProperties={register('password', {
                                required: true,
                            })}
                        />
                        {errors.password && (
                            <span className="text-error-1">
                                Password cannot be empty
                            </span>
                        )}
                    </div>

                    <AppFormSubmitButton
                        type="submit"
                        isEnabled={!isLoggingIn}
                        value={isLoggingIn ? 'Loading...' : 'Login'}
                        sizeClassName="w-auto h-8 px-4"
                    />
                </form>
            </AppCard>
        </AppContainer>
    );
};
export default LoginPage;
