import React, {useEffect} from "react";
import AppButton from "../../components/common/inputs/AppButton";
import {useLocation, useNavigate} from "react-router-dom";
import AppCard from "../../components/common/layout/AppCard";
import AppContainer from "../../components/common/layout/AppContainer";
import {Helmet} from "react-helmet";

const EmbedDetectionPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ((window.top === null
            || window.top === window.self)) {
            navigate("/", {replace: true})
        }
    }, [location.pathname, navigate])

    const redirect = () => {
        window.open("/", "_blank");
    }

    return (
        <AppContainer outerClassName="h-full w-full"
    innerClassName="flex items-center justify-center">
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <AppCard
                className="text-accent-1"
                title="Want to play?"
            >
             <div className="flex flex-col w-full items-center justify-center">
                 <p className="text-center my-8">
                     Please click the button below to play on the official site!
                 </p>

                 <AppButton className="uppercase" onClick={redirect}>Let's go</AppButton>
             </div>
            </AppCard>
        </AppContainer>
    )
};

export default EmbedDetectionPage;
